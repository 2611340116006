<template>
    <div
        class="w-full h-full py-10 overflow-auto bg-gradient-to-br from-white via-purple-300 to-pink-400 bg-clip-padding backdrop-filter backdrop-blur-sm">
        <form @submit.prevent="handleSubmit"
            class="w-full flex flex-col justify-center items-center gap-y-6 md:gap-y-10">
            <Stepper v-model="stepIndex" class="flex w-full items-start px-2 gap-2">
                <StepperItem v-for="step in steps" :key="step.step" v-slot="{ state }"
                    class="relative flex w-full flex-col items-center justify-center" :step="step.step">
                    <StepperSeparator v-if="step.step !== steps[steps.length - 1].step"
                        class="absolute left-[calc(50%+20px)] right-[calc(-50%+10px)] top-5 block h-0.5 shrink-0 rounded-full bg-muted group-data-[state=completed]:bg-primary" />

                    <StepperTrigger as-child>
                        <Button :variant="state === 'completed' || state === 'active' ? 'default' : 'outline'"
                            size="icon" class="z-10 rounded-full shrink-0"
                            :class="[state === 'active' && 'ring-2 ring-ring ring-offset-2 ring-offset-background']"
                            :disabled="state !== 'completed' && !form.meta.value.valid || numberOfSpins === 0">
                            <Check v-if="state === 'completed'" class="size-5" />
                            <Circle v-if="state === 'active'" />
                            <Dot v-if="state === 'inactive'" />
                        </Button>
                    </StepperTrigger>

                    <div class="mt-5 flex flex-col items-center text-center">
                        <StepperTitle :class="[state === 'active' && 'text-primary']"
                            class="text-sm font-semibold transition lg:text-base">
                            {{ step.title }}
                        </StepperTitle>
                        <StepperDescription :class="[state === 'active' && 'text-primary']"
                            class="sr-only text-xs text-muted-foreground transition md:not-sr-only lg:text-sm">
                            {{ step.description }}
                        </StepperDescription>
                    </div>
                </StepperItem>
            </Stepper>

            <div>
                <template v-if="stepIndex === 1">
                    <div class="w-96 sm:w-[450px] md:w-[500px] flex flex-col gap-y-4 px-10">
                        <FormField v-slot="{ componentField }" name="FullName" label="Họ và tên">
                            <FormItem>
                                <FormLabel :isRequired="true">Họ và tên</FormLabel>
                                <FormControl>
                                    <Input v-model:model-value="customerInfo.FullName"
                                        :default-value="customerInfo.FullName" type="text"
                                        placeholder="Nhập đầy đủ họ tên" v-bind="componentField" autofocus
                                        :error="form.errors.value.FullName" />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        </FormField>
                        <FormField v-slot="{ componentField }" name="Phone" label="Số điện thoại">
                            <FormItem>
                                <FormLabel :isRequired="true">Số điện thoại</FormLabel>
                                <FormControl>
                                    <Input v-model:model-value="customerInfo.Phone" :defaultValue="customerInfo.Phone"
                                        type="tel" pattern="[0-9]*" placeholder="Ex: 0123456789" v-bind="componentField"
                                        :error="form.errors.value.Phone" />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        </FormField>
                        <FormField v-slot="{ componentField }" name="Email" label="Email">
                            <FormItem>
                                <FormLabel :isRequired="true">Email</FormLabel>
                                <FormControl>
                                    <Input v-model:model-value="customerInfo.Email" :defaultValue="customerInfo.Email"
                                        type="text" placeholder="Ex: example@gmail.com" v-bind="componentField"
                                        :error="form.errors.value.Email" />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        </FormField>
                        <div class="flex justify-between md:text-lg">
                            <FormField v-slot="{ value, handleChange, componentField }" name="IsAccepted">
                                <FormItem class="flex flex-col gap-y-1">
                                    <div class="flex flex-row items-center space-y-0">
                                        <FormControl>
                                            <Checkbox v-bind="componentField" :checked="value"
                                                @update:checked="handleChange" />
                                        </FormControl>
                                        <div class="leading-none ml-2">
                                            <FormLabel class="cursor-pointer md:text-base">Tôi đồng ý với các điều khoản
                                                của chương trình!
                                            </FormLabel>
                                        </div>
                                    </div>

                                    <FormMessage :error="form.errors.value.IsAccepted" />
                                </FormItem>
                            </FormField>
                        </div>
                    </div>
                </template>

                <template v-if="stepIndex === 2">
                    <div class="flex flex-col items-center">
                        <div class="flex items-center justify-center pb-6 md:pb-10">
                            <p class="font-medium md:text-xl">Số lượt quay còn lại: {{ numberOfSpins }}</p>
                        </div>

                        <div class="relative w-[300px] h-[300px]">
                            <div class="onlstreet-spin-lucky"
                                style="color: rgb(255, 255, 255); font-size: 18px; font-weight: bold; text-align: center;">
                                <div class="onlstreet-spin-lucky-screen">
                                    <div class="onlstreet-spin-lucky-label" v-for="(phoneCard, index) in phoneCards"
                                        :key="index"
                                        :style="{ 'transform': 'rotate(' + (index * 60 - 30) + 'deg) translateY(-50%)', '-webkit-transform': 'rotate(' + (index * 60 - 30) + 'deg) translateY(-50%)' }">
                                        {{ phoneCard }}</div>
                                </div>
                                <button type="button" class="onlstreet-spin-lucky-start" title="Quay"
                                    v-on:click="spin"></button>
                            </div>
                        </div>

                        <p class="pt-6 px-6 md:pt-10"><span class="font-medium">Hướng dẫn:</span> Bấm vào mũi kim giữa
                            vòng quay
                            để bắt đầu
                            quay
                            thưởng.</p>

                    </div>
                </template>

                <template v-if="stepIndex === 3">

                </template>
            </div>

            <div class="w-96 sm:w-[450px] md:w-[500px] flex items-center justify-between px-10 ">
                <Button v-if="canGoBack" :disabled="!canGoBack" variant="outline" size="sm" @click="goBack">
                    Trước
                </Button>

                <div class="flex items-center gap-4 ml-auto">
                    <Button variant="outline" v-if="stepIndex == 1" type="submit" :disabled="!canGoNext" size="sm"
                        v-on:click="form.meta.value.valid && goNext()">
                        Tiếp theo
                    </Button>
                    <!-- <Button variant="primary" v-if="stepIndex == 2" size="sm" type="submit">
                        Xác nhận
                    </Button> -->
                </div>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import Button from '@/components/ui/button/Button.vue';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Checkbox } from '@/components/ui/checkbox'
import { required, email, phone, handleRequestErrors } from '@/js/functions/handleRequestErrors';
import { Check, Circle, Dot } from 'lucide-vue-next'
import { get, set } from '@vueuse/core'
import { Stepper, StepperDescription, StepperItem, StepperSeparator, StepperTitle, StepperTrigger } from '@/components/ui/stepper'
import { useForm } from 'vee-validate';
import spinEventRequests from '@/js/utils/spinEventRequests';
import { toast } from 'vue3-toastify';

const rotation = ref(0)
let currentIdx = 15
const numberOfSpins = ref(0)

const phoneCards = ['Nước táo - Thùng', 'Nước táo - Hộp', 'Bộ tứ Xynapsa', 'Thanh lương thảo', 'Thiệt thanh thảo', 'Không trúng']

var luckyScreenRotation = computed(() => {
    return 'rotate(' + rotation.value + 'deg)';
})

const spin = async () => {
    if (numberOfSpins.value > 0) {
        try {
            await spinEventRequests.spinOnlstreet(customerInfo.Phone).then((res) => {
                if (res.data.IsSuccess) {
                    const newIdx = res.data.NewIdx
                    rotation.value = 360 * currentIdx + 360 - 60 * newIdx - 60
                    currentIdx += 15
                    numberOfSpins.value--

                    if (res.data.NewIdx === 5) {
                        setTimeout(() => {
                            toast.error('Chúc bạn may mắn lần sau');
                        }, 8500);
                    } else {
                        setTimeout(() => {
                            toast.success(res.data.Msg)
                        }, 8500);
                    }
                } else {
                    toast.error(res.data.Msg)
                }
            })
        } catch (error) {
            handleRequestErrors(error)
        }
    } else {
        toast.error('Bạn đã hết lượt quay')
    }
}

// const $loading: { [key: string]: any } | undefined = inject('$loading')

const stepIndex = ref(1)

const steps = [
    {
        step: 1,
        title: 'Thông tin',
        description: 'Nhập thông tin cần thiết để xác thực',
    },
    {
        step: 2,
        title: 'Quay thưởng',
        description: 'Tiến hành quay thưởng để nhận những phần quà cực lớn',
    },
    {
        step: 3,
        title: 'Kết quả',
        description: 'Xem lại kết quả quay thưởng',
    },
]

const customerInfo = reactive({
    FullName: localStorage.getItem('FullName') || '',
    Phone: localStorage.getItem('Phone') || '',
    Email: localStorage.getItem('Email') || ''
})

const formSchema = [
    {
        FullName: { required, min: 4, max: 50 },
        Phone: { required, phone },
        Email: { required, email },
        IsAccepted: { isAccepted: [true] },
    },
    // z.object({
    //     password: z.string().min(2).max(50),
    //     confirmPassword: z.string(),
    // }).refine(
    //     (values) => {
    //         return values.password === values.confirmPassword
    //     },
    //     {
    //         message: 'Passwords must match!',
    //         path: ['confirmPassword'],
    //     },
    // )
]

const form = useForm({
    validationSchema: formSchema[stepIndex.value - 1],
    validateOnMount: false,
})

const handleSubmit = form.handleSubmit(async (value: any) => {
    form.validate()
    if (stepIndex.value === steps.length) {
        onSubmit(value)
    }
})

const canGoNext = computed(() => stepIndex.value < steps.length)
const canGoBack = computed(() => stepIndex.value > 1)

const goNext = async () => {
    if (get(canGoNext)) {
        try {
            await spinEventRequests.getNumberOfSpinsOnlstreet(customerInfo.Phone, customerInfo.FullName, customerInfo.Email).then((res) => {
                numberOfSpins.value = res.data

                if (res.data > 0) {
                    set(stepIndex, stepIndex.value + 1)
                } else {
                    alert('Bạn đã hết lượt quay')
                }
            })
        } catch (error) {
            handleRequestErrors(error)
        } finally {
            localStorage.setItem('FullName', customerInfo.FullName)
            localStorage.setItem('Phone', customerInfo.Phone)
            localStorage.setItem('Email', customerInfo.Email)
        }
    }
}
function goBack() {
    if (get(canGoBack)) {
        set(stepIndex, stepIndex.value - 1)
    }
}

function onSubmit(values: any) {
    console.log(values);
}

</script>

<style scoped>
/* Sử dụng Tailwind CSS classes */
.onlstreet-spin-lucky {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    box-shadow: 0px 0px 7px 0px rgba(64, 64, 64, 0.6), 0 8px 50px rgba(64, 64, 64, 0.3);
    background-repeat: no-repeat;
    background-size: cover;
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.onlstreet-spin-lucky .onlstreet-spin-lucky-screen {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    text-decoration-line: inherit;
    text-transform: inherit;

    -webkit-transform: v-bind(luckyScreenRotation);
    transform: v-bind(luckyScreenRotation);

    -webkit-transition: transform 8000ms cubic-bezier(0.25, 0.1, 0, 1);
    transition: transform 8000ms cubic-bezier(0.25, 0.1, 0, 1);
}

.onlstreet-spin-lucky .onlstreet-spin-lucky-screen:before {
    background-image: url('../../assets/svgs/spin-lucky-04.svg');
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

.onlstreet-spin-lucky .onlstreet-spin-lucky-screen:before {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
}

.onlstreet-spin-lucky .onlstreet-spin-lucky-label {
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: hidden;
    width: 42%;
    padding-left: 12%;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    text-decoration-line: inherit;
    text-transform: inherit;
    line-height: 1.6;
    text-shadow: rgba(0, 0, 0, 0.5) 1px 0px 2px;
}

.onlstreet-spin-lucky .onlstreet-spin-lucky-start {
    background-image: url('../../assets/svgs/spin-btn.svg');
}

.onlstreet-spin-lucky .onlstreet-spin-lucky-start {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 20%;
    height: 20%;
    cursor: pointer;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    transition: transform 300ms ease-in-out;
    -webkit-transition: transform 300ms ease-in-out;
}

.onlstreet-spin-lucky .onlstreet-spin-lucky-start:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}
</style>