import { toast } from "vue3-toastify";
import { localize } from "@vee-validate/i18n";
import { defineRule, configure } from "vee-validate";
import { required, min, max, email } from "@vee-validate/rules";

const phone = (value: any) => {
  if (value.toString().length < 10 || value.toString().length > 12) {
    return false;
  }

  const regex = /[^0-9]/;

  if (regex.test(value.toString())) {
    return false;
  }

  return true; // Replace with your validation logic
};

const isAccepted = (value: boolean, params: any): boolean => {
  if (value === params[0]) {
    return true;
  }

  return false;
};

defineRule("required", required);
defineRule("min", min);
defineRule("max", max);
defineRule("email", email);
defineRule("phone", phone);
defineRule("isAccepted", isAccepted);

export { required, min, max, email, phone };

configure({
  // create and set a localization handler
  generateMessage: localize("vi", {
    messages: {
      required: "{field} không được để trống!",
      min: "{field} phải có ít nhất 0:{length} ký tự!",
      max: `{field} chỉ được chứa tối đa 0:{length} ký tự!`,
      email: "Email không đúng định dạng!",
      phone: "Số điện thoại phải có 10 chữ số!",
      isAccepted: "Bạn phải đồng ý điều khoản trước khi tiếp tục!",
    },
  }),
});

export const handleRequestErrors = (error: any) => {
  if (error.response) {
    const errors = error.response.data;

    if (errors.status == 400) {
      toast.error(errors.title);
      return errors;
    } else if (errors.status == 401) {
      toast.error(errors?.errors);
    } else {
      toast.error(errors.errors);
    }
  } else {
    toast.error(error?.message);
  }
};
