import { createApp } from 'vue'
import './assets/index.css'
import App from './App.vue'
import { createPinia } from 'pinia'
import { LoadingPlugin } from 'vue-loading-overlay'
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css';

const pinia = createPinia()
const app = createApp(App)

app.use(pinia)

app.use(LoadingPlugin, {
  color: '#50B83C',
  loader: 'dots',
  backgroundColor: '#c3c3c3'
})

// app.filter!('toCurrency', (value: any) => currencyFormat(value))

const options = {
  autoClose: 3000,
  hideProgressBar: false,
  pauseOnHover: true,
  closeOnClick: true,
  transition: 'flip',
  newestOnTop: true,
  theme: 'auto'
} as ToastContainerOptions

app.use(Vue3Toastify, options as ToastContainerOptions)

app.mount('#app')