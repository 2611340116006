<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { ref } from 'vue'
import type { CheckboxRootEmits, CheckboxRootProps } from 'radix-vue'
import { CheckboxIndicator, CheckboxRoot, useForwardPropsEmits } from 'radix-vue'
import { cn } from '@/lib/utils'

const props = defineProps<CheckboxRootProps>()
const emits = defineEmits<CheckboxRootEmits>()

const forwarded = useForwardPropsEmits(props, emits)

const checked = ref(false)

const handleChecked = () => {
  checked.value = !checked.value
}
</script>

<template>
  <div class="checkbox-wrapper flex items-center">
    <CheckboxRoot
      v-on:update:checked="handleChecked"
      v-bind="forwarded"
      :class="
        cn(
          'peer h-4 w-4 px-0.5 rounded border-2 border-primary/80 box-border focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-[#50B83C] data-[state=checked]:text-primary-foreground hover:opacity-75 focus:border-[#50B83C] focus:bg-secondary data-[state=checked]:focus:border-green-600',
          $attrs.class ?? ''
        )
      "
    >
      <CheckboxIndicator class="h-full w-full flex items-center justify-center text-current">
        <svg
          class="!w-3 !h-3 text-[#50B83C]"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 16 12"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
            d="M1 5.917 5.724 10.5 15 1.5"
          />
        </svg>
      </CheckboxIndicator>
    </CheckboxRoot>

    <div class="text-sm hover:cursor-pointer hover:text-[#50B83C]" v-on:click="handleChecked">
      <slot />
    </div>
  </div>
</template>

<style scoped></style>
