import { baseRequests } from "./baseRequests";

class spinEventRequests {
  async getNumberOfSpinsOnlstreet(phoneNumber, fullName, email) {
    return await baseRequests().get(
      `SpinEvent/getNumberOfSpinsOnlstreet?phoneNumber=${phoneNumber}&fullName=${fullName}&email=${email}`
    );
  }

  async spinOnlstreet(phoneNumber){
    return await baseRequests().get(`SpinEvent/spinOnlstreet?phoneNumber=${phoneNumber}`);
  }
}

export default new spinEventRequests();
